import "amazon-connect-streams"

const initState = {
  connectObject: connect,
  agentObject: null,
  ARN: null,
  phoneNumber: '',
  isIncomingCall: false,
  isAcceptedCall: false,
  isIncomingChat: false,
  isAcceptedChat: false,
  missedChat: false,
  callHistory: [],
  agentStatus: false
}

/**
 * The reducer function that handles the Amazon connect related values in Redux store
 * @param {Object} state the state object 
 * @param {Object} action the action object
 * @returns {Object}
 */
const connectReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CONNECT":
      return {
        ...state,
        connectObject: action.payload
      }

    case "SET_AGENT":
      return {
        ...state,
        agentObject: action.payload
      }

    case "SET_ARN":
      return {
        ...state,
        ARN: action.payload
      }

    case "SET_IS_INCOMING":
      return {
        ...state,
        isIncomingCall: action.payload
      }

    case "SET_IS_CHAT_INCOMING":
      return {
        ...state,
        isIncomingChat: action.payload
      }

    case "SET_MISSED_CHAT":
      console.log(">>> Missed chat:", action.payload)
      return {
        ...state,
        missedChat: action.payload
      }

    case "SET_IS_ACCEPTED":
      return {
        ...state,
        isAcceptedCall: action.payload
      }

    case "SET_IS_CHAT_ACCEPTED":
      return {
        ...state,
        isAcceptedChat: action.payload
      }
    case "SET_PHONE_NUMBER":
      return {
        ...state,
        phoneNumber: action.payload
      }
    case "SET_CALL_HISTORY":
      return {
        ...state,
        callHistory: action.payload
      }
    case "SET_AGENT_STATUS":
      return {
        ...state,
        agentStatus: action.payload
      }
    default:
      return state
  }
}

export default connectReducer