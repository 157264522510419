const initState = {
  instanceName: null,
  instanceId: localStorage.getItem('instanceId') || null,
  instanceARN: null
}

/**
 * The reducer function that stores the Amazon connect instance values in Redux store
 * @param {Object} state the state object 
 * @param {Object} action the action object
 * @returns {Object}
 */
const instanceReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_INSTANCE_NAME":
      return {
        ...state,
        instanceName: action.payload
      }

    case "SET_INSTANCE_ID":
      return {
        ...state,
        instanceId: action.payload
      }

    case "SET_INSTANCE_ARN":
      return {
        ...state,
        instanceARN: action.payload
      }

    default:
      return state
  }
}

export default instanceReducer