// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chatReducer from './chat'
import connectReducer from './connect'
import instanceIdReducer from './instance'
import widgetReducer from './widget'
import gridLayoutReducer from './grid'
import socketReducer from './socket'


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat: chatReducer,
  connect: connectReducer,
  instance: instanceIdReducer,
  widget: widgetReducer,
  gridLayout: gridLayoutReducer,
  socket: socketReducer
})

export default rootReducer
