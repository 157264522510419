

// import "amazon-connect-streams"
const initialState = {
    chatSession: false,
    chats: [],
    contacts: [],
    chatsContacts: [],
    userProfile: {},
    selectedUser: {},
    chatIds: []
}

const addChatContacts = (state, action) => {
    const { chatsContacts } = state

    if (action.payload && action.payload !== 'undefined') {
        // Only add contact id if not exist yet
        if (!chatsContacts.includes(action.payload)) {
            chatsContacts[action.payload] = {}
        }
        console.log(">>> ADD_CHAT_CONTACTS :", chatsContacts)
        return { ...state, chatsContacts }
    }

    return state
}

const populateChatContacts = (state, action) => {
    const { chatsContacts } = state
    // Only add contact id if not exist yet
    if (action.payload.contactId) {

        if (!chatsContacts.includes(action.payload.contactId)) {
            // chatsContact.push(action.payload)
            chatsContacts[action.payload.contactId] = {
                cnn: action.payload.cnn,
                contact: action.payload.contact,
                chatSession: action.payload.agentChatSession
            }
        }
        // console.log(">>> POPULATE_CHAT_CONTACTS payload:", action.payload)
        console.log(">>> POPULATE_CHAT_CONTACTS :", chatsContacts)
        return { ...state, chatsContacts }
    }
    return state
}

const removeChatContacts = (state, action) => {
    const { chatsContacts, chats } = state

    // Remove contact id only if exist in state
    for (const iterator in chatsContacts) {
        if (iterator === action.payload) {
            delete chatsContacts[iterator]
        }
    }
    const newChats = chats.filter((v) => v.userId !== action.payload)
    // return state
    return { ...state, chatsContacts, chats: newChats }
}

const setContacts = (state, action) => {

    const { chatsContacts, chats, contacts } = state
    const newChatsContacts = chatsContacts
    // let newSelectedUser = state.selectedUser

    // console.log(">>> SELECTED USER: ", newSelectedUser)

    // Only add contact id if not exist yet
    if (action.payload && !chatsContacts[action.payload.id]) {
        contacts.push(action.payload)

        newChatsContacts[action.payload.id] = {}

        // if (!newSelectedUser ? newSelectedUser : !newSelectedUser.contact) {
        //     // Get event id from URL
        //     const userId = action.payload.id

        //     const chat = chats.find(c => c.id === userId)
        //     if (chat) chat.unseenMsgs = 0
        //     const contact = contacts.find(c => c.id === userId)
        //     if (contact && contact.chat) contact.chat.unseenMsgs = 0

        //     newSelectedUser = { chat, contact }
        // }
        // console.log(">>> Set SELECTED USER :", newSelectedUser)
    }
    // return { ...state, contacts, selectedUser: newSelectedUser }
    return { ...state, contacts, chatsContacts: newChatsContacts }
}

const removeContactAndChat = (state, action) => {
    const { chats, contacts } = state
    let newRemovedSelectedUser = state.selectedUser

    const removeContacts = contacts.filter(v => v.id !== action.payload)
    const newChat = chats.filter(v => v.userId !== action.payload)

    if (removeContacts && removeContacts.length) {
        if (
            (newRemovedSelectedUser && newRemovedSelectedUser.contact) &&
            newRemovedSelectedUser.contact.id === action.payload
        ) {
            // Get event id from URL
            const userId = removeContacts[0].id

            const chat = newChat.find(c => c.id === userId)
            if (chat) chat.unseenMsgs = 0
            const contact = contacts.find(c => c.id === userId)
            if (contact && contact.chat) contact.chat.unseenMsgs = 0

            newRemovedSelectedUser = { chat, contact }
        } else {
            newRemovedSelectedUser = {}
        }
    } else {
        newRemovedSelectedUser = {}
    }
    console.log(">>> REMOVE_CONTACTS :")
    console.info(removeContacts)
    console.log(">>> SELECTED_USER :")
    console.info(newRemovedSelectedUser)
    console.log(">>> NEW_CHAT :")
    console.info(newChat)

    const newState = { ...state, contacts: removeContacts, selectedUser: newRemovedSelectedUser, chats: newChat }

    console.log(">>> NEW_STATE :")
    console.info(newState)
    return newState
}


const addChat = (state, action) => {
    const { chats, selectedUser, contacts } = state
    const userIndex = chats.findIndex(el => el.userId === action.payload.userId)

    if (userIndex === -1) {
        chats.push(action.payload)

    } else {
        const userObject = chats[userIndex]
        const chatTranscript = userObject.chat
        const isChatExists = chatTranscript.filter(el => el.id === action.payload.chat.id)

        if (!isChatExists.length) {
            userObject.chat.push(action.payload.chat)
            chats[userIndex] = { ...userObject }
        }
    }

    if (selectedUser && selectedUser.contact && selectedUser.contact.id) {
        if (selectedUser.contact.id === action.payload.userId) {
            selectedUser.chat = (userIndex === -1) ? chats[0] : chats[userIndex]
        }
    }

    console.log(">>> contacts:", contacts)
    const newContacts = chats.map(chat => {
        const contact = contacts.find(c => c.id === action.payload.userId)
        if (contact) {
            // contact.chat = { id: chat.userId, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
            contact.chat = { id: chat.userId }
        }
        return contact
    })
    console.log(">>> newContacts:", newContacts)

    return { ...state, chats, selectedUser }
}

const loadAgentChat = (state, action) => {
    const { chats, selectedUser, contacts } = state
    const userIndex = chats.findIndex(el => el.userId === action.payload.userId)

    if (userIndex === -1) {
        chats.push(action.payload)
    }

    if (selectedUser && selectedUser.contact && selectedUser.contact.id) {
        if (selectedUser.contact.id === action.payload.userId) {
            selectedUser.chat = (userIndex === -1) ? chats[0] : chats[userIndex]
        }
    }

    const newContacts = chats.map(chat => {
        const contact = contacts.find(c => c.id === action.payload.userId)
        if (contact) {
            contact.chat = { id: chat.userId, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
        }
        return contact
    })
    return { ...state, chats, selectedUser }
}


const incomingChat = (state, action) => {

    console.log(">>> INCOMING_CHAT:")
    console.info(action.payload)

    const { chats, selectedUser, contacts } = state
    const userChatIndex = chats.findIndex(el => el.userId === action.payload.userId)

    if (userChatIndex !== -1) {
        const userObject = chats[userChatIndex]
        const chatTranscript = userObject.chat
        const isChatExists = chatTranscript.findIndex(el => el.id === action.payload.chat.id)

        if (isChatExists === -1) {
            userObject.chat.push(action.payload.chat)

            const cleanedChat = userObject.chat.filter(el => !Array.isArray(el))
            userObject.chat = cleanedChat

            chats[userChatIndex] = { ...userObject }

            // if (selectedUser.contact && selectedUser.contact.id) {
            //     if (selectedUser.contact.id === action.payload.userId) {
            //         selectedUser.chat.chat.push(action.payload.chat)
            //     }
            // }
            console.log(">>> contacts:", contacts)

            const newContacts = chats.map(chat => {
                const contact = contacts.find(c => c.id === chat.userId)
                if (contact.id === action.payload.userId) {

                    contact.chat = { id: chat.userId, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
                }
                return contact
            })
            console.log(">>> newContacts:", newContacts)

        }
    }


    return { ...state, chats, selectedUser }
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        // AWS related
        case 'SET_CHAT_SESSION':
            return { ...state, chatSession: action.payload }
            break
        case 'ADD_CHAT_CONTACTS':
            return addChatContacts(state, action)
            break
        case 'POPULATE_CHAT_CONTACTS':
            return populateChatContacts(state, action)
            break
        case 'REMOVE_CHAT_CONTACTS':
            return removeChatContacts(state, action)
            break

        // Chat Template related
        case 'SET_USER_PROFILE':
            return { ...state, userProfile: action.userProfile }
            break
        case 'GET_CHAT_CONTACTS':
            return { ...state, contacts: action.data.contacts }
            break
        case 'SET_CONTACTS':
            return setContacts(state, action)
            break
        case 'REMOVE_CONTACT_AND_CHAT':
            return removeContactAndChat(state, action)
            break
        case 'SELECT_CHAT':
            return { ...state, selectedUser: action.data }
            break
        case 'SEND_MSG':
            //  ** Add new msg to chat
            const newMsg = action.chat
            return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
            break
        case 'ADD_CHAT': // from aws event
            return addChat(state, action)
            break
        case 'LOAD_AGENT_CHAT': // from aws event
            return loadAgentChat(state, action)
            break
        case 'INCOMING_CHAT':
            return incomingChat(state, action)
            break

        // return state
        default:
            return state
    }
}


export default chatReducer