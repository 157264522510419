
const initialState = []

/**
 * The reducer function that sets the grid layout array value in Redux store
 * @param {Array} state the state object 
 * @param {Object} action the action object
 * @returns {Array}
 */
const gridLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GRID_LAYOUT":
      return [
        ...state,
        ...action.payload
      ]
    
    default:
      return state
  }
}

export default gridLayoutReducer