// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
/* const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
} */

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: themeConfig.layout.menu.isCollapsed,
  menuHidden: themeConfig.layout.menu.isHidden,
  rightSideMenuHidden: false,
  rightSideMenuTab: '1',
  layoutExtraWidth: 0,
  contentWidth: themeConfig.layout.contentWidth,
  theme: themeConfig.layout.skin,
  isLoading: true
}

/**
 * The reducer function that handles the layout values in Redux store
 * @param {Object} state the state object 
 * @param {Object} action the action object
 * @returns {Object}
 */
const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_CONTENT_WIDTH':
      return { ...state, contentWidth: action.value }
    case 'HANDLE_MENU_COLLAPSED':
      //window.localStorage.setItem('menuCollapsed', action.value)
      return { ...state, menuCollapsed: action.value }
    case 'HANDLE_MENU_HIDDEN':
      return { ...state, menuHidden: action.value }
    case 'SET_Right_MENU_HIDDEN':
      return { ...state, rightSideMenuHidden: action.value }
    case 'SET_Right_MENU_TAB':
      return { ...state, rightSideMenuTab: action.value }
    case 'SET_LAYOUT_EXTRA_WIDTH':
      return { ...state, layoutExtraWidth: action.value }
    case 'HANDLE_RTL':
      return { ...state, isRTL: action.value }
    case 'SET_THEME_MODE':
      return { ...state, theme: action.value }
    case 'SET_LOADING':
      return { ...state, isLoading: action.value }
    default:
      return state
  }
}

export default layoutReducer
