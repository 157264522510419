
const initialState = {
  widgetContent: null
}

/**
 * The reducer function that stores a widget id value in Redux store
 * @param {Object} state the state object 
 * @param {Object} action the action object
 * @returns {Object}
 */
const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WIDGET_CONTENT":
      return {
        ...state,
        widgetContent: action.payload
      }

    default:
      return state
  }
}

export default widgetReducer